import { Logo } from '../components/logo'
import { Button } from '../components/button'
import { IconBackground } from '../components/IconBackground'
import React from 'react'
import Layout from '../components/mdxLayout'

export default () => (
  <Layout>
    <div className="bg-grape">
      <div className="relative overflow-hidden">
        <div className="text-white opacity-10">
          <IconBackground/>
        </div>
        <div className="relative pb-16 sm:pb-18">
          <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
                Brand guidelines
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-white opacity-50 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                We're thrilled you want to use our logo!
              </p>
              <div className="my-4">
                <a
                  className="border inline-flex items-center space-x-1 border-gray-300 shadow-sm bg-white text-grape rounded-lg font-medium text-lg p-4 px-6 hover:gray-50"
                  href="https://try.be/trybe-branding.zip"
                  title="Download logos and icons"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" strokeLinejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                  </svg>
                  <span>Download logos and icons</span>
                </a>
                <div className="mt-2 space-x-4 text-xs text-white text-opacity-50">
                  Available in SVG, PNG and a Figma file.
                </div>
              </div>
              <p className="text-lg text-white text-opacity-50">
                Please follow these guidelines to ensure it always looks great.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-white">
      <div className="max-w-5xl p-4 sm:p-6 lg:p-8 mx-auto">
        <h2 className="py-4 text-2xl font-medium">Logo colours</h2>

        <p>
          Our logo is available in three variants:
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4">
          <div className="bg-white rounded-md p-8 items-center justify-center">
            <Logo variant="light"/>
            <div className="text-sm font-medium mt-4">Use this one for light coloured backgrounds</div>
          </div>
          <div className="bg-purple rounded-md p-8 items-center justify-center">
            <Logo variant="dark"/>
            <div className="text-sm font-medium text-white mt-4">Use this one for dark coloured backgrounds</div>
          </div>
          <div className="bg-coral rounded-md p-8 items-center justify-center">
            <Logo variant="bright"/>
            <div className="text-sm font-medium mt-4 text-white">Use this one for brightly coloured backgrounds</div>
          </div>
        </div>

        <h2 className="py-4 text-2xl font-medium">Full logo vs Icon</h2>

        <p>
          Our logo can be represented as a full logo containing the Trybe name, or as an icon when space is limited.
        </p>

        <div className="grid grid-cols-3 gap-6 mt-4 md:w-1/2">
          <div className="bg-white rounded-md p-8 flex items-center justify-center">
            <Logo icon variant="light" width={27}/>
          </div>
          <div className="bg-purple rounded-md p-8 flex items-center justify-center">
            <Logo icon variant="dark" width={27}/>
          </div>
          <div className="bg-coral rounded-md p-8 flex items-center justify-center">
            <Logo icon variant="bright" width={27}/>
          </div>
        </div>

        <h2 className="py-4 text-2xl font-medium">Exclusion zone</h2>

        <p>
          It's important that there is sufficient spacing around the logo. This includes usage of both the full logo, and the icon on its own.
        </p>
        
        <p>
          The exclusion zone is equal to half the height of the icon. The spacing you should include is highlighted below.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4">
          <div className="bg-white rounded-md p-8 flex items-center justify-center">
            <div style={{border: '13.5px dashed rgba(255,0,60,0.25)'}}>
              <Logo variant="light"/>
            </div>
            <div className="text-sm font-medium">
              Lorem ipsum
            </div>
          </div>
          <div className="bg-purple rounded-md p-8 flex items-center justify-center">
            <div style={{border: '13.5px dashed rgba(255,255,255,0.25)'}}>
              <Logo variant="dark"/>
            </div>
            <div className="text-white text-sm font-medium">
              Lorem ipsum
            </div>
          </div>
          <div className="bg-coral rounded-md p-8 flex items-center justify-center">
            <div style={{border: '13.5px dashed rgba(0,0,0,0.25)'}}>
              <Logo variant="bright"/>
            </div>
            <div className="text-white text-sm font-medium">
              Lorem ipsum
            </div>
          </div>
        </div>

        <h2 className="py-4 text-2xl font-medium">Minimum sizing</h2>

        <p>
          The height of both the icon and the full logo should not be smaller than 20px.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4 md:w-2/3">
          <div className="bg-white rounded-md p-8 flex items-center justify-center">
            <Logo variant="light" width={110}/>
          </div>
          <div className="bg-purple rounded-md p-8 flex items-center justify-center">
            <Logo variant="dark" width={110}/>
          </div>
          <div className="bg-coral rounded-md p-8 flex items-center justify-center">
            <Logo variant="bright" width={110}/>
          </div>
        </div>

        <h2 className="py-4 text-2xl font-medium">Colour palette</h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4">
          <div className="bg-coral rounded-md p-8 text-center text-white">
            <div className="text-xl font-medium">Coral</div>
            <div>#FF0066</div>
            <div>rgb(255, 0, 6)</div>
          </div>
          <div className="bg-purple rounded-md p-8 text-center text-white">
            <div className="text-xl font-medium">Grape</div>
            <div>#330033</div>
            <div>rgb(51, 0, 51)</div>
          </div>
          <div className="bg-violet rounded-md p-8 text-center">
            <div className="text-xl font-medium ">Violet</div>
            <div>#9999FF</div>
            <div>rgb(153, 153, 255)</div>
          </div>
        </div>

        <h2 className="py-4 text-2xl font-medium">Tips</h2>

        <ul className="space-y-8">
          <li>
            <div>
              <strong>✅ Do</strong> use the correct variant for the background colour the logo will be placed on.
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4">
                <div className="relative rounded-md p-8 flex items-center justify-center" style={{backgroundColor: '#fcffd4'}}>
                  <Logo variant="light"/>
                  <div className="absolute bottom-2 right-2 text-xs bg-white rounded-lg px-2 py-1">✅ Looks great</div>
                </div>
                <div className="relative rounded-md p-8 flex items-center justify-center" style={{backgroundColor: '#394a4f'}}>
                  <Logo variant="dark"/>
                  <div className="absolute bottom-2 right-2 text-xs bg-white rounded-lg px-2 py-1">✅ All good</div>
                </div>
                <div className="relative rounded-md p-8 flex items-center justify-center" style={{backgroundColor: '#ff6634'}}>
                  <Logo variant="bright"/>
                  <div className="absolute bottom-2 right-2 text-xs bg-white rounded-lg px-2 py-1">✅ Nice!</div>
                </div>
                <div className="relative rounded-md p-8 flex items-center justify-center" style={{backgroundColor: '#eee'}}>
                  <Logo variant="dark"/>
                  <div className="absolute bottom-2 right-2 text-xs bg-white rounded-lg px-2 py-1">⛔️ Nope</div>
                </div>
                <div className="relative rounded-md p-8 flex items-center justify-center" style={{backgroundColor: '#2c213b'}}>
                  <Logo variant="light"/>
                  <div className="absolute bottom-2 right-2 text-xs bg-white rounded-lg px-2 py-1">⛔️ That sucks</div>
                </div>
                <div className="relative rounded-md p-8 flex items-center justify-center" style={{backgroundColor: '#fcffd4'}}>
                  <Logo variant="bright"/>
                  <div className="absolute bottom-2 right-2 text-xs bg-white rounded-lg px-2 py-1">⛔️ Try again</div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div>
              <strong>✅ Do</strong> ensure there's sufficient space around our logo.
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4">
                <div className="relative rounded-md p-8 flex items-center justify-center bg-violet">
                  <div style={{margin: '0 13.5px'}}>
                    <Logo variant="bright"/>
                  </div>
                  <div className="font-medium">We use Trybe!</div>
                  <div className="absolute bottom-2 right-2 text-xs bg-white rounded-lg px-2 py-1">✅ Oi oi</div>
                </div>
                <div className="relative rounded-md p-8 flex items-center justify-center bg-coral">
                  <Logo variant="bright"/>
                  <div className="font-medium">We use Trybe!</div>
                  <div className="absolute bottom-2 right-2 text-xs bg-white rounded-lg px-2 py-1">⛔️ Oi!</div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div>
              <strong>⛔️ Don't</strong> modify the logo colours in any way. Only use the provided variants
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4">
                <div className="relative rounded-md p-8 flex items-center justify-center bg-white">
                  <svg className="transition" xmlns="http://www.w3.org/2000/svg" view-box="0 0 131.25 27.25" width="130" style={{minHeight: '20px'}}>
                    <path fill="#f06" d="M45.45 8.37h-4.59a.54.54 0 01-.54-.53v-2a.54.54 0 01.54-.54h13.86a.54.54 0 01.53.54v2a.53.53 0 01-.53.53h-4.59a.54.54 0 00-.54.54v12.52a.54.54 0 01-.54.54h-2.53a.54.54 0 01-.53-.54V8.91a.54.54 0 00-.54-.54zM59.87 5.82a.54.54 0 01.54-.54H67c4.91 0 7.49 2.89 7.49 6.47a6 6 0 01-2.92 5.16.26.26 0 00-.08.37l3 4.26a.27.27 0 01-.22.43H71a.55.55 0 01-.45-.25l-2.23-3.38a.52.52 0 00-.52-.24 7.62 7.62 0 01-1.08.08H64a.54.54 0 00-.54.54v2.71a.54.54 0 01-.53.54h-2.52a.54.54 0 01-.54-.54zm7.26 9.27a3.38 3.38 0 100-6.74H64a.54.54 0 00-.54.53v5.68a.54.54 0 00.54.53zM83.48 14.29l-5.61-8.17a.54.54 0 01.44-.84h2.81a.54.54 0 01.45.24l2.54 3.73c.43.62.81 1.28 1 1.69a.26.26 0 00.46 0c.25-.42.65-1.09 1-1.7l2.53-3.71a.55.55 0 01.45-.25h2.81a.54.54 0 01.45.84l-5.54 8.14a.54.54 0 00-.1.3v6.87a.54.54 0 01-.53.54h-2.53a.54.54 0 01-.54-.54v-6.84a.53.53 0 00-.09-.3zM97.86 5.82a.54.54 0 01.53-.54h7.34c2.76 0 5.2 1.16 5.2 4.35a3.7 3.7 0 01-1.23 2.67.26.26 0 00.07.44 4.23 4.23 0 012.68 4.08c0 3.52-2.78 5.15-6.46 5.15h-7.6a.54.54 0 01-.53-.54zm7.62 5.85c1.26 0 1.88-.62 1.88-1.62s-.62-1.68-2-1.68H102a.54.54 0 00-.54.54v2.22a.54.54 0 00.54.54zm.33 7.21c1.73 0 2.88-.62 2.88-2.11s-1.08-2.19-2.67-2.19h-4a.54.54 0 00-.54.53v3.23a.54.54 0 00.54.54zM118.37 5.28h12a.54.54 0 01.54.54v2a.54.54 0 01-.54.53H122a.54.54 0 00-.54.54v2.32a.54.54 0 00.54.54h7.88a.54.54 0 01.54.54v2a.54.54 0 01-.54.53H122a.54.54 0 00-.54.54v3a.54.54 0 00.54.54h8.73a.54.54 0 01.54.53v2a.54.54 0 01-.54.54h-12.36a.54.54 0 01-.53-.54V5.82a.54.54 0 01.53-.54z"/>
                    <path d="M23.71 27.06l-4.66-3.17a1.07 1.07 0 01-.47-.9l.16-8.91c0-1.46.11-3 .18-4a.53.53 0 00-.78-.51c-.84.42-2.21 1.09-3.61 1.63l-8.41 3.3a1.08 1.08 0 01-1-.11L.47 11.24a1.08 1.08 0 01.21-1.89L24.65 0a.53.53 0 01.73.5v25.68a1.06 1.06 0 01-1.67.88z" fill="#f06"/>
                  </svg>
                  <div className="absolute bottom-2 right-2 text-xs bg-white rounded-lg px-2 py-1">⛔️ Sorry, no</div>
                </div>
                <div className="relative rounded-md p-8 flex items-center justify-center bg-coral">
                  <svg className="transition" xmlns="http://www.w3.org/2000/svg" view-box="0 0 131.25 27.25" width="130" style={{minHeight: '20px'}}>
                    <path fill="#330033" d="M45.45 8.37h-4.59a.54.54 0 01-.54-.53v-2a.54.54 0 01.54-.54h13.86a.54.54 0 01.53.54v2a.53.53 0 01-.53.53h-4.59a.54.54 0 00-.54.54v12.52a.54.54 0 01-.54.54h-2.53a.54.54 0 01-.53-.54V8.91a.54.54 0 00-.54-.54zM59.87 5.82a.54.54 0 01.54-.54H67c4.91 0 7.49 2.89 7.49 6.47a6 6 0 01-2.92 5.16.26.26 0 00-.08.37l3 4.26a.27.27 0 01-.22.43H71a.55.55 0 01-.45-.25l-2.23-3.38a.52.52 0 00-.52-.24 7.62 7.62 0 01-1.08.08H64a.54.54 0 00-.54.54v2.71a.54.54 0 01-.53.54h-2.52a.54.54 0 01-.54-.54zm7.26 9.27a3.38 3.38 0 100-6.74H64a.54.54 0 00-.54.53v5.68a.54.54 0 00.54.53zM83.48 14.29l-5.61-8.17a.54.54 0 01.44-.84h2.81a.54.54 0 01.45.24l2.54 3.73c.43.62.81 1.28 1 1.69a.26.26 0 00.46 0c.25-.42.65-1.09 1-1.7l2.53-3.71a.55.55 0 01.45-.25h2.81a.54.54 0 01.45.84l-5.54 8.14a.54.54 0 00-.1.3v6.87a.54.54 0 01-.53.54h-2.53a.54.54 0 01-.54-.54v-6.84a.53.53 0 00-.09-.3zM97.86 5.82a.54.54 0 01.53-.54h7.34c2.76 0 5.2 1.16 5.2 4.35a3.7 3.7 0 01-1.23 2.67.26.26 0 00.07.44 4.23 4.23 0 012.68 4.08c0 3.52-2.78 5.15-6.46 5.15h-7.6a.54.54 0 01-.53-.54zm7.62 5.85c1.26 0 1.88-.62 1.88-1.62s-.62-1.68-2-1.68H102a.54.54 0 00-.54.54v2.22a.54.54 0 00.54.54zm.33 7.21c1.73 0 2.88-.62 2.88-2.11s-1.08-2.19-2.67-2.19h-4a.54.54 0 00-.54.53v3.23a.54.54 0 00.54.54zM118.37 5.28h12a.54.54 0 01.54.54v2a.54.54 0 01-.54.53H122a.54.54 0 00-.54.54v2.32a.54.54 0 00.54.54h7.88a.54.54 0 01.54.54v2a.54.54 0 01-.54.53H122a.54.54 0 00-.54.54v3a.54.54 0 00.54.54h8.73a.54.54 0 01.54.53v2a.54.54 0 01-.54.54h-12.36a.54.54 0 01-.53-.54V5.82a.54.54 0 01.53-.54z"/>
                    <path d="M23.71 27.06l-4.66-3.17a1.07 1.07 0 01-.47-.9l.16-8.91c0-1.46.11-3 .18-4a.53.53 0 00-.78-.51c-.84.42-2.21 1.09-3.61 1.63l-8.41 3.3a1.08 1.08 0 01-1-.11L.47 11.24a1.08 1.08 0 01.21-1.89L24.65 0a.53.53 0 01.73.5v25.68a1.06 1.06 0 01-1.67.88z" fill="#fff"/>
                  </svg>
                  <div className="absolute bottom-2 right-2 text-xs bg-white rounded-lg px-2 py-1">⛔️ Nah m8</div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div>
              <strong>⛔️ Don't</strong> try to recreate the lettering of the logo
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4">
                <div className="relative rounded-md p-8 flex items-center justify-center bg-white">
                  <svg className="transition" xmlns="http://www.w3.org/2000/svg" view-box="0 0 27 27.25" width="27" style={{minHeight: '20px'}}>
                    <path d="M23.71 27.06l-4.66-3.17a1.07 1.07 0 01-.47-.9l.16-8.91c0-1.46.11-3 .18-4a.53.53 0 00-.78-.51c-.84.42-2.21 1.09-3.61 1.63l-8.41 3.3a1.08 1.08 0 01-1-.11L.47 11.24a1.08 1.08 0 01.21-1.89L24.65 0a.53.53 0 01.73.5v25.68a1.06 1.06 0 01-1.67.88z" fill="#f06"/>
                  </svg>
                  <div className="font-medium text-xl ml-1">TRYBE</div>
                  <div className="absolute bottom-2 right-2 text-xs bg-white rounded-lg px-2 py-1">⛔️ Gross</div>
                </div>
                <div className="relative rounded-md p-8 flex items-center justify-center bg-coral">
                  <svg className="transition" xmlns="http://www.w3.org/2000/svg" view-box="0 0 27 27.25" width="27" style={{minHeight: '20px'}}>
                    <path d="M23.71 27.06l-4.66-3.17a1.07 1.07 0 01-.47-.9l.16-8.91c0-1.46.11-3 .18-4a.53.53 0 00-.78-.51c-.84.42-2.21 1.09-3.61 1.63l-8.41 3.3a1.08 1.08 0 01-1-.11L.47 11.24a1.08 1.08 0 01.21-1.89L24.65 0a.53.53 0 01.73.5v25.68a1.06 1.06 0 01-1.67.88z" fill="#fff"/>
                  </svg>
                  {/* <div className="text-xl ml-1 text-white" style="font-family: 'Comic Sans MS', 'Comic Sans', serif">TRYBE</div> */}
                  <div className="absolute bottom-2 right-2 text-xs bg-white rounded-lg px-2 py-1">⛔️ Lol really?</div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
)
